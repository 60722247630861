<template>
  <ManageAppLookup dialog-name="Apps Lookup" />
</template>
<script>
import ManageAppLookup from '@/views/app/manage/AppLookup'

export default {
  name: 'AppLookupWrapper',
  components: {
    ManageAppLookup
  }
}
</script>
